import * as React from "react";
import './ActivationForm.css';
import { Trans, useTranslation, withTranslation } from "react-i18next";
import { useFormState, useFormDispatch } from "./Context/ActivationFormContext"
import RedwoodInputText from '../../../common/Input/RedwoodInputText';
import RedwoodSelect from '../../../common/Select/RedwoodSelect';
import { regExp } from '../../../common/Validators';
import PasswordComponent from "./Password/PasswordComponent";
import * as Bowser from "bowser";
import { signup_environment, themeOn } from "../../../config/apiConfig";
import SendMetrics from "../../../CourierService/SendMetrics";
import SendLogs from "../../../CourierService/SendLogs";
import TermsOfUseComponent from "../../TermsOfUse/TermsOfUseComponent";
import Notifier from "../../../common/Notifier/Notifier";
import RedwoodModal from "../../../common/Modal/RedwoodModal";
import { keysIn } from "lodash";
import { fetchAccountNameValidation, fetchBanner, fetchRegions } from "../../../CourierService/FetchService";
// import { mockRegionsResponse } from "../../../mocks/FetchMocks";

const FormDisplay = () => {
  const dispatch = useFormDispatch()
  const [touched, setTouched] = React.useState({});
  const [filterRegions, setFilterRegions] = React.useState<boolean>(false)
  const [perfillError, setPerfillError] = React.useState<boolean>(false);
  const [passwordError, setPasswordError] = React.useState({passwordValid: null, matchPassword: null});
  const [tenancyNameChanged, setTenancyNameChanged] = React.useState<boolean>(false);
  const {t} = useTranslation();
  const errorState = {isValid: true, value: '', errorMsg: ''};
  const {
    user_session,
    apiError,
    render,
    firstName,
    lastName,
    emailAddress,
    tenancyName,
    dataCenterRegion,
    dataCenterRegionId,
    serviceGroupNames,
    homeRegion,
    homeRegions,
    showBanner,
    isInternalCustomerType,
    domainType,
    tenancyTypes
  } = useFormState();

  const unavailableError = React.useMemo(() => t('activationForm.error.unavailable', 'The chosen Cloud Account Name is unavailable'), [t]);

  const formText: {[key: string]: {[key: string]: string | any }} = {
    label: {
      newAccountInformation: t('activationForm.label', "New Cloud Account Information"),
      firstName: t('activationForm.firstName', 'First Name'),
      lastName: t('activationForm.lastName', 'Last Name'),
      email: t('activationForm.email', 'Email'),
      tenancyName: t('activationForm.tenancyName', 'Cloud Account Name'),
      homeRegion: t('activationForm.homeRegion', 'Home Region'),
      tenancyType: t('activationForm.tenancyType', 'Cloud Account Type')
    },
    info: {
      tenancyName: <Trans i18nKey="activationForm.tenancyNameInfoTemp">Avoid including personal or confidential information when creating a cloud account name, since it cannot be changed later, and the name will be visible in the login URL. <br/><br/>The Cloud Account Name must be lowercase, start with a letter, contain no spaces or special characters, and be 25 or less characters long. The name will be assigned to your company's or organization's environment when signing into the Console.</Trans>,
      homeRegion: <Trans i18nKey="activationForm.homeRegionInfo">See <a href="https://www.oracle.com/cloud/cloud-regions/data-regions/#northamerica" target='_blank' rel="noopener noreferrer">OCI service availability</a> by Regions. SaaS applications are provisioned in the geo-region specified on your order.</Trans>,
      tenancyType: <Trans i18nKey="activationForm.tenancyTypeInfo">Service infrastructure Tenancy should be used by Internal Oracle Service teams that need a tenancy for service principals, or any Tenancy that is used as part of region build or host/run service components.</Trans>
    }
  }

  const [error, setError] = React.useState({
    firstName:errorState,
    lastName:errorState,
    emailAddress:errorState,
    tenancyName:errorState,
    homeRegion:errorState
  });

  const getErrorText = (type?: string, insert?: string) => {
    switch (type) {
      case "required":
        return <Trans i18nKey="activationForm.error.required">A value for {{insert}} is required.</Trans>;
      case "length":
        return <Trans i18nKey="activationForm.error.length">Cloud account name must be 25 or less characters long.</Trans>;
      case "regExpr":
        return <Trans i18nKey="activationForm.error.regExpr">Input requirements for {{insert}} not met.</Trans>;
      case "tenancyReq":
        return <Trans i18nKey="activationForm.error.tenancyReq">Cloud account does not meet requirements.</Trans>
      default:
        return t('activationForm.error.gen', 'Error');
    }
  }

  const passwordErrorFlag = (name: any, value: any) => {
    setPasswordError(passwordError => {
      return{...passwordError, ...{[name]: value}}
    });
  };

  const setApiError = React.useCallback(() => {
    if (!apiError) {
      dispatch({
        type:'UPDATE_APIERROR',
        payload: 'boolean',
        boolean: true
      });
    } else {
      setPerfillError(true);
    };
  },[dispatch, apiError]);

  const onChangeHandler = (e: { target: { name: any; }; }, action: string, payload: any) => {
    dispatch({
      type:`${action}`,
      payload: payload
    });
    setTouched({...touched, [e.target.name]: true})
  }

  const onBlurHandler = (e: { target: { value: string; name: any; }; }, pattern: string, action: string) => {
    let val = e.target.value.trim()
    const name = e.target.name;
    dispatch({type: `${action}`, payload: val});

    setTouched({...touched, [name]: false})

    if(val.length === 0) {
      setError(error => {
        return {...error, ...{[name]: {isValid: false, value: '', errorMsg: getErrorText("required", formText.label[name])}}}
      });
    } else if(!val.match(pattern) || val === '') {
      setError(error => {
        return {...error, ...{[name]: {isValid: false, value: '', errorMsg: getErrorText("regExpr", formText.label[name])}}}
      });
    } else {
      setError(error => {
        return {...error, ...{[name]: {isValid: true, value: val, errorMsg: ''}}}
      });
      setTouched({...touched, [name]: true})
    }
  }

  // Check Available Tenancy Name
  React.useEffect(() => {
    function setTenancyError(setting: boolean) {
      if (setting) {
        setError(error => {
          return {...error, ...{tenancyName: {isValid: false, value: '', errorMsg: unavailableError.toString()}}}
        });
      } else {
        setError(error => {
          return {...error, ...{tenancyName: {isValid: true, value: tenancyName, errorMsg: ''}}}
        });
        setTouched({...touched, tenancyName: true})
      };
    }

    if (tenancyNameChanged) {
      fetchAccountNameValidation(tenancyName)
      .then(async accountNameValidationResult => {
        setTenancyNameChanged(false)
        if(accountNameValidationResult.status !== 204) {
          setTenancyError(true);
          SendMetrics({name:"ActivationValidateTenancyName.Count.Metrics.Error", value:1, userSession:user_session});
        } else {
          setTenancyError(false);
          SendMetrics({name:"ActivationValidateTenancyName.Count.Metrics", value:1, userSession:user_session});
        }
      }).catch(error => {
        SendLogs({severity: 40000, message: "Validate updated tenancy name fail: " + error.message, userSession:user_session});
        SendMetrics({name:"ActivationValidateTenancyName.Count.Metrics.Fail", value:1, userSession:user_session});
      });
    }
  },[tenancyName, tenancyNameChanged, touched, setTouched, unavailableError, user_session]);


  const onBlurTenancyNameHandler = (e: { target: { value: string; name: any; }; }, pattern: string, action: string) => {
    let val = e.target.value.trim()
    const name = e.target.name;
    dispatch({type: `${action}`, payload: val});

    setTouched({...touched, [name]: false})

    if(val.length === 0) {
      setError(error => {
        return {...error, ...{[name]: {isValid: false, value: '', errorMsg: getErrorText("required", formText.label[name])}}}
      });
    } else if (val.length > 25) {
      setError(error => {
        return {...error, ...{[name]: {isValid: false, value: '', errorMsg: getErrorText("length", formText.label[name])}}}
      });
    } else if(!val.match(pattern) || val === '') {
      setError(error => {
        return {...error, ...{[name]: {isValid: false, value: '', errorMsg: getErrorText("tenancyReq", formText.label[name])}}}
      });
    } else {
      setTenancyNameChanged(true);
    }
  }

  const updateTouched = (name: any, value: any) => {
    setTouched(touched => {
      return{...touched, ...{[name]: value}}
    });
  };

  // Browser, OS and Platform Metrics
  React.useEffect(() => {
    const userAgent = Bowser.parse(window.navigator.userAgent);

    //Browser
    const browserName = userAgent.browser.name? userAgent.browser.name : "";
    const knownBrowserList: {[key: string]: string} = {
      'Chrome': 'Chrome.Count.Metrics',
      'Safari': 'Safari.Count.Metrics',
      'Firefox': 'Firefox.Count.Metrics',
      'Microsoft Edge': 'Edge.Count.Metrics',
      'Internet Explorer': 'IE.Count.Metrics'
    }

    if(knownBrowserList[browserName]) {
      SendMetrics({name: "Activation." + knownBrowserList[browserName], value:1, userSession: user_session});
    } else {
      SendMetrics({name: "ActivationUnknown.Count.Metrics", value: 1, userSession: user_session});
    }

    //OS
    const osname = userAgent.os.name? userAgent.os.name : "";
    const knownOSList: {[key: string]: string} = {
      'macOS': 'Mac.Count.Metrics',
      'Linux': 'Linux.Count.Metrics',
      'Windows': 'Windows.Count.Metrics'
    }

    if(knownOSList[osname]) {
      SendMetrics({name: "Activation." + knownOSList[osname], value: 1, userSession: user_session});
    }

    //Platform
    const platform = userAgent.platform.type? userAgent.platform.type : "";
    const knownPlatformList: {[key: string]: string} = {
      'desktop': 'Desktop.Count.Metrics',
      'mobile': 'Mobile.Count.Metrics',
      'tablet': 'Tablet.Count.Metrics',
    }

    if (knownPlatformList[platform]){
      SendMetrics({name: "Activation." + knownPlatformList[platform], value: 1, userSession: user_session});
    }
  }, [render, user_session]);

  // GET Home regions list
  React.useEffect(() => {
    // serviceGroupNames to check
    const serviceGroupNamesCheck = [
      "MOATOUTCOMES",
      "MOATANALYTICS",
      "MOATPRO",
      "MOATREACH",
      "DATAAPPEND",
      "OPTIMIZATIONODC",
      "CONTEXTODC",
      "CXUNITY"
    ];

    // push to serviceGroupNames for local only
    // serviceGroupNames.push("MOATOUTCOMES", "UGBUGAM");
    // serviceGroupNames.push("UGBUGAM");

    // fake result for local only
    // const result = mockRegionsResponse;

    const regionIdString: string = dataCenterRegionId !== '' ? '&dataCenterRegion=' + dataCenterRegionId : '';
    const requestString: string = `/region?${serviceGroupNames.includes('ORACLEDBATAZURE') ? 'serviceType=Indigo&' : ''}${dataCenterRegion ? ('classicDataCenterRegionId=' + dataCenterRegion + regionIdString) : ''}`;

    fetchRegions(requestString)
    .then(async res => {
      if(res.status === 200) {
        const result = await res.json();
        let list: any[] = [];
        if(serviceGroupNames.length > 0) {
          serviceGroupNames.forEach((serviceGroupName =>{
            if (serviceGroupNamesCheck.includes(serviceGroupName)) {
              setFilterRegions(true)
            }
          }));
        }
        if (filterRegions) {
          if (serviceGroupNames.includes("CXUNITY")) {
            result.items.filter((homeRegion: { homeRegions: any[]; id: any; regionName: any; }) => {
              if( homeRegion.id === "NORTH_AMERICA_REGION" || homeRegion.id === "EMEA_REGION" || homeRegion.id === "APAC_REGION") {
                return homeRegion
              }})
            .map((homeRegion: { homeRegions: any[]; id: any; regionName: any; }) => { 
              const subList = homeRegion.homeRegions.map(subRegion => {
                return({label: subRegion.regionName, value: {id: subRegion.id}, group: homeRegion.id});
              });
              const dataRegion = {label: homeRegion.regionName,value: homeRegion.id, options: subList}
              list = list.concat(dataRegion);
              return homeRegion;
            });
          } else {
            result.items.filter((homeRegion: { homeRegions: any[]; id: any; regionName: any; }) => {
              if(homeRegion.id === "NORTH_AMERICA_REGION") {
                return homeRegion
              }})
            .map((homeRegion: { homeRegions: any[]; id: any; regionName: any; }) => { 
              const subList = homeRegion.homeRegions.map(subRegion => {
                return({label: subRegion.regionName, value: {id: subRegion.id}, group: homeRegion.id});
              });
              const dataRegion = {label: homeRegion.regionName,value: homeRegion.id, options: subList}
              list = list.concat(dataRegion);
              return homeRegion;
            });
          }
        } else {
          result.items.map((homeRegion: { homeRegions: any[]; id: any; regionName: any; }) => {
            const subList = homeRegion.homeRegions.map(subRegion => {
              return({label: subRegion.regionName, value: {id: subRegion.id}, group: homeRegion.id});
            });
            const dataRegion = {label: homeRegion.regionName,value: homeRegion.id, options: subList}
            list = list.concat(dataRegion);
            return homeRegion;
          });
        }
        if (dataCenterRegion === 'DED-ONPREM' || dataCenterRegion === 'DED-COLO' || serviceGroupNames.includes('ORACLEDBATAZURE')) {
          // @ts-ignore
          dispatch({
            type: 'HOME_REGIONS_LIST',
            payload: "object",
            object: list
          });
        } else if (signup_environment === 'dev' || signup_environment === 'pintlab2') {
          const homeRegionList = [{
            label: 'North America',
            options: [
              {
                label: 'US Dev West (Seattle)',
                value: {id: 'us-seattle-1'},
                group: 'NORTH_AMERICA_REGION'
              },
              {
                label: "US Dev West (West Jordan)",
                value: {id: "us-westjordan-1"},
                group: "NORTH_AMERICA_REGION"
              },
              {
                label: "US East (Ashburn)",
                value: {id: "us-ashburn-1"},
                group: "NORTH_AMERICA_REGION"
              }
            ]
          }];
          dispatch({
            type: 'HOME_REGIONS_LIST',
            payload: "object",
            object: homeRegionList
          });
        } else if (signup_environment === 'pintlab') {
          const homeRegionList = [{
            label: 'North America',
            options: [
              {
                label: 'US Dev West (Seattle)',
                value: {id: 'us-seattle-1'},
                group: 'NORTH_AMERICA_REGION'
              },
              {
                label: "US Dev West (West Jordan)",
                value: {id: "us-westjordan-1"},
                group: "NORTH_AMERICA_REGION"
              },
              {
                label: "US East (Ashburn)",
                value: {id: "us-ashburn-1"},
                group: "NORTH_AMERICA_REGION"
              },
              {
                label: "Canada Southeast (Montreal)",
                value: {id: "ca-montreal-1"},
                group: "NORTH_AMERICA_REGION"
              }
            ]},
            {
              label: 'EUROPE, MIDDLE EAST AND AFRICA',
              options: [
                {
                  label: 'Germany Central (Frankfurt)',
                  value: {id: 'eu-frankfurt-1'},
                  group: 'EMEA_REGION'
                }
              ]},
            {
              label: 'Asia-Pacific',
              options: [
                {
                  label: 'Japan Central (Osaka)',
                  value: {id: 'ap-osaka-1'},
                  group: 'APAC_REGION'
                },
                {
                  label: "Japan East (Tokyo)",
                  value: {id: "ap-tokyo-1"},
                  group: "APAC_REGION"
                }
              ]
            }];
          dispatch({
            type: 'HOME_REGIONS_LIST',
            payload: "object",
            object: homeRegionList
          });
        } else {
          dispatch({
            type: 'HOME_REGIONS_LIST',
            payload: "object",
            object: list
          });
        }
      }

      if(res.status >= 400) {
        SendMetrics({name:"ActivationRegion.Count.Metrics.Error", value:1, userSession:user_session});
        setApiError();
      }
      SendLogs({severity: 20000, message: "GET Home regions list successful", userSession:user_session});
      SendMetrics({name:"ActivationRegion.Count.Metrics", value:1, userSession:user_session});
    }).catch(error => {
      console.info(error);
      SendLogs({severity: 40000, message: "GET Home regions list error: " + error.message, userSession:user_session});
      SendMetrics({name:"ActivationRegion.Count.Metrics.Fail", value:1, userSession:user_session});
      setApiError();
    });
  }, [render, dispatch, user_session, setApiError, dataCenterRegion, dataCenterRegionId, serviceGroupNames, setFilterRegions, filterRegions]);

  // GET banner
  React.useEffect(() => {
    const checkBanner = (bannerStartTime: number, bannerEndTime: number) => {
      const currentTime = new Date().getTime();
      if (currentTime >= bannerStartTime && currentTime <= bannerEndTime) {
        dispatch({
          type: "UPDATE_SHOW_BANNER",
          payload: "boolean",
          boolean: true
        });
      }
    };

    fetchBanner()
    .then(async res => {
      if(res.status === 200) {
          const result = await res.json();
          // second item in array for activation
          const banner = result.items[1];
          if (banner) {
            dispatch({
              type: "UPDATE_BANNER_CONTENT",
              payload: "object",
              object: banner
            });
          }
          checkBanner(Date.parse(banner.startTime), Date.parse(banner.endTime));
      }
      if(res.status >= 400) {
        SendMetrics({name:"ActivationBanner.Count.Metrics.Error", value:1, userSession:user_session});
      }
      SendLogs({severity: 20000, message: "GET banner list successful", userSession:user_session});
      SendMetrics({name:"ActivationBanner.Count.Metrics", value:1, userSession:user_session});
    }).catch(error => {
      console.log(error);
      SendLogs({severity: 40000, message: "GET banner list error: " + error.message, userSession:user_session});
      SendMetrics({name:"ActivationBanner.Count.Metrics.Fail", value:1, userSession:user_session});
    })
  }, [render, user_session, dispatch]);

  // Validate preset values
  React.useEffect(() => {
    let keys = keysIn(touched)

    const presetValidation = async(e: { target: { value: string; name: any; }; }, action: string) => {
      let val = e.target.value.trim()
      const name = e.target.name;
      setTouched(touched => {
        return{...touched, ...{[name]: true}}
      });
      dispatch({type: `${action}`, payload: val});
    }

    if (firstName && firstName.length > 1 && keys.indexOf('firstName') < 0) {
      const fName: { target: { value: string; name: any; }} = { target: { name: 'firstName', value: firstName}};
      const setFname = async () => {
        await presetValidation(fName, 'UPDATE_FIRST_NAME');
      }
      setFname();
    }

    if (lastName && lastName.length > 1 && keys.indexOf('lastName') < 0) {
      const lName: { target: { value: string; name: any; }} = { target: { name: 'lastName', value: lastName}};
      const setLname = async () => {
        await presetValidation(lName, 'UPDATE_LAST_NAME');
      }
      setLname();
    }

    if (emailAddress && emailAddress.length > 1 && keys.indexOf('emailAddress') < 0) {
      const email: { target: { value: string; name: any; }} = { target: { name: 'emailAddress', value: emailAddress}};
      const setEmail = async () => {
        await presetValidation(email, 'UPDATE_EMAIL_ADDRESS');
      }
      setEmail();
    }

    if (tenancyName && tenancyName.length > 1 && keys.indexOf('tenancyName') < 0) {
      const tName: { target: { value: string; name: any; }} = { target: { name: 'tenancyName', value: tenancyName}};
      const setTenancyName = async () => {
        await presetValidation(tName, 'UPDATE_TENANCY_NAME');
      }
      setTenancyName();
    }

  },[firstName, lastName, emailAddress, tenancyName, dispatch, touched, setTouched]);

  const disableContinue = () => {
    if(showBanner){
      return true;
    }

    const errorCheck = () => {
      const eflags: string[] = [];
      Object.entries(error).forEach(key =>  {
        if(key[0] in touched && key[1].isValid){
          eflags.push("false");
        } else {
          eflags.push("true");
        }
      });
      const eflag = eflags.find((flag) => flag === "true") ? true : false;
      return eflag
    }

    const passCheck = () => {
      if (passwordError.passwordValid && passwordError.matchPassword) {
        return false;
      }
      return true;
    }

    const flag: boolean = errorCheck() || passCheck();

    return flag;
  }

  const closeErrorModal = () => {
    dispatch({
      type:'UPDATE_APIERROR',
      payload: 'boolean',
      boolean: false
    });
  }

  return (
    <>
      <div className="upperContentContainer">
        {apiError &&
          <RedwoodModal
            onClose={() => closeErrorModal()}
            show={true}
            buttonName={"Close"}
            wrapperClass={"sticky"}
          >
            <Notifier type="error" message={t('activationForm.error.gen', "Error")} position={perfillError ? 'apiError' : 'createError'} />
          </RedwoodModal>
        }
        <form autoComplete="off">
          <div className="title6">{formText.label.newAccountInformation}</div>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <RedwoodInputText
                type="text"
                placeholder={formText.label.firstName}
                label={formText.label.firstName}
                name="firstName"
                value={firstName? firstName : ""}
                error={error.firstName.errorMsg}
                valid={error.firstName.isValid}
                required
                onChange={(e: { target: any; }) => onChangeHandler(e, 'UPDATE_FIRST_NAME', e.target.value)}
                onBlur={(e: { target: { value: string; name: any; }; }) => {onBlurHandler(e, regExp.personName, 'UPDATE_FIRST_NAME');}}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <RedwoodInputText
                type="text"
                placeholder={formText.label.lastName}
                label={formText.label.lastName}
                name="lastName"
                value={lastName? lastName : ""}
                error={error.lastName.errorMsg}
                valid={error.lastName.isValid}
                required
                onChange={(e: { target: any; }) => onChangeHandler(e, 'UPDATE_LAST_NAME', e.target.value)}
                onBlur={(e: { target: { value: string; name: any; }; }) => onBlurHandler(e, regExp.personName, 'UPDATE_LAST_NAME')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <RedwoodInputText
                type="text"
                placeholder={formText.label.email}
                label={formText.label.email}
                name="emailAddress"
                value={emailAddress}
                valid={error.emailAddress.isValid}
                error={error.emailAddress.errorMsg}
                required
                onChange={(e: { target: any; }) => onChangeHandler(e, 'UPDATE_EMAIL_ADDRESS', e.target.value)}
                onBlur={(e: { target: { value: string; name: any; }; }) => onBlurHandler(e, regExp.email, 'UPDATE_EMAIL_ADDRESS')}
              />
            </div>
          </div>
          <div className="row">
            <PasswordComponent updateTouched={updateTouched} updatePasswordErrorFlag={passwordErrorFlag} passwordError={passwordError}/>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <RedwoodInputText
                type="text"
                placeholder={formText.label.tenancyName}
                label={formText.label.tenancyName}
                name="tenancyName"
                value={tenancyName}
                error={error.tenancyName.errorMsg}
                valid={error.tenancyName.isValid}
                info={true}
                infoMsg={formText.info.tenancyName}
                required
                onChange={(e: { target: any; }) => onChangeHandler(e, 'UPDATE_TENANCY_NAME', e.target.value)}
                onBlur={(e: { target: { value: string; name: any; }; }) => onBlurTenancyNameHandler(e, regExp.tenancy, 'UPDATE_TENANCY_NAME')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <RedwoodSelect
                placeholder={formText.label.homeRegion}
                label={formText.label.homeRegion}
                name="homeRegion"
                required
                value={homeRegion}
                data={homeRegions}
                info={themeOn !== true}
                infoMsg={formText.info.homeRegion}
                onChange={(e: any) => {
                  setTouched({...touched, "homeRegion": true})
                  dispatch({
                    type:`UPDATE_HOME_REGION`,
                    payload: "object",
                    object: e
                  });
                }}
              />
            </div>
          </div>
          <></>
          {isInternalCustomerType && themeOn !== true &&
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <RedwoodSelect
                placeholder={formText.label.tenancyType}
                label={formText.label.tenancyType}
                name="tenancyType"
                value={domainType}
                data={tenancyTypes}
                info={true}
                infoMsg={formText.info.tenancyType}
                onChange={(e: any) => {
                  setTouched({...touched, "domainType": true})
                  dispatch({
                    type:`UPDATE_DOMAIN_TYPE`,
                    payload: "object",
                    object: e
                  });
                }}
              />
            </div>
          </div>
          }
        </form>
      </div>
      <TermsOfUseComponent status={disableContinue()}/>
    </>
  );
}

const ActivationFormContainer: React.FC<{}> = () => {
  return (
    <div className="contentContainer">
      <FormDisplay />
    </div>
  );
};

export default withTranslation()(ActivationFormContainer);
